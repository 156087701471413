import React from 'react';
import { Block, Layout, LinkTo, PillButton } from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO } from '../../../components/_index';

const ContestRules = () => {
  return (
    <PageLayout>
      <SEO
        title="Open Source Money Contest Rules"
        description="Contest Rules for the Open Source Money Contest"
      />
      <Layout dark background="blue" header="Contest Rules" />

      <Layout>
        <Block type="full">
          <div className="blog">
            <PillButton type="link" to="/contests/open-source-money">
              &#10554; back to contest
            </PillButton>
            <br />
            <br />
            <div className="blog-article">
              <div className="article-body">
                <p>
                  NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF
                  WINNING. ALL FEDERAL, STATE, LOCAL, AND MUNICIPAL LAWS AND REGULATIONS APPLY. VOID WHERE
                  PROHIBITED.
                </p>
                <ol>
                  <li>
                    <b>Eligibility</b>
                    <p>
                      Open Source Money: Provably Fair 500,000 DRGN Giveaway (the &quot;Contest&quot;) is open
                      to legal residents of the fifty (50) United States and the District of Columbia who are
                      at least eighteen (18) years old at the time of entry, and to residents of other
                      jurisdictions where the Contest is compliant with applicable laws and regulations. These
                      rules are available in the English language only. Employees of The Dragon Company and
                      other companies associated with the promotion of the Contest, and their respective
                      parents, subsidiaries, affiliates and advertising and promotion agencies as well as the
                      immediate family (spouse, parents, siblings, and children) and household members of each
                      such employee are not eligible. The Contest is subject to federal, state, and local laws
                      and regulations.
                    </p>
                  </li>
                  <li>
                    <b>Sponsor</b>
                    <p>The Contest is sponsored by The Dragon Company of Bellevue, Washington USA.</p>
                  </li>
                  <li>
                    <b>Agreement to Official Rules</b>
                    <p>
                      Participation in the Contest constitutes entrant&apos;s full and unconditional agreement
                      to and acceptance of these Official Rules and the decisions of the Sponsor, which are
                      final and binding. Winning a prize is contingent upon being compliant with these
                      Official Rules and fulfilling all other requirements set forth herein.
                    </p>
                  </li>
                  <li>
                    <b>Contest Period</b>
                    <p>
                      The Contest begins on July 4, 2020 at 10:30 a.m. EST and ends on August 6, 2020 at EST
                      (the &quot;Contest Period&quot;). Entries that are submitted before or after the Contest
                      Period will be disqualified. Submissions will be accepted online only for the duration
                      of the Contest.
                    </p>
                  </li>
                  <li>
                    <b>How to Enter</b>
                    <p>
                      <ol>
                        <li>
                          Watch Super Happy Dragon Lucky on YouTube at 11 a.m. Eastern on Thursdays, starting
                          on June 25, 2020. During each episode, Dragonchain will announce the prize for the
                          following week’s contest and provide a clue to obtaining the answer to the question
                          on the weekly contest entry form.
                        </li>
                        <li>Watch the next episode of Open Source Money on the Science Channel.</li>
                        <li>
                          Visit the official Contest page at&nbsp;
                          <LinkTo type="route" to="/contests/open-source-money">
                            https://dragonchain.com/contests/open-source-money
                          </LinkTo>
                          &nbsp; and correctly answer a question about an Open Source Money scene.
                        </li>
                      </ol>
                    </p>
                    <p>
                      One entry per person per week. Multiple entries subject to disqualification. All entries
                      become the property of Sponsor and will not be acknowledged or returned.
                    </p>
                  </li>
                  <li>
                    <b>Prize Drawings</b>
                    <p>
                      Entries are recorded directly onto the Dragonchain system (the “System”) and checked for
                      correct answers. For selection, the System uses future hashes of a predefined block
                      height of Bitcoin and Ethereum. The combined hashes are used to select the winner based
                      on an algorithm described below:
                    </p>
                    <code>
                      <pre>{`btc = <BTC timestamp + 10 blocks>
eth = <ETH timestamp + 10 blocks>
<repeat for other active interchains>
selection_hash = hash(btc,eth,*)
selection = python -c 'print(int("<selection_hash>", 16) % <number of entries>)'
`}</pre>
                    </code>
                    <p>
                      Weekly prize winners will be announced live on Super Happy Dragon Lucky episodes in
                      July. On or about August 13, 2020, the Sponsor will select potential Grand Prize winners
                      in a random drawing from among all eligible entries received. The odds of being selected
                      depend on the number of entries received. The Sponsor will attempt to notify the
                      potential winner internet message or email on or about August 13, 2020 If the potential
                      winner cannot be contacted within five (5) days after the date of the first attempt to
                      contact him/her, the Sponsor may select an alternate potential winner in his/her place
                      at random from the remaining non-winning, eligible entries.
                    </p>
                  </li>
                  <li>
                    <b>Winner Notification</b>
                    <p>
                      The potential winners will be notified by publication on the System and live on the
                      August 13, 2020 episode of Super Happy Dragon Lucky. Each potential Grand Prize winner
                      (parent/legal guardian if a minor in his/her state of residence) will be required to
                      complete, electronically sign and submit a Declaration of Compliance within five (5)
                      days of the date notice or attempted notice is sent, in order to claim his/her prize. If
                      a potential winner cannot be contacted, or fails to submit the Declaration of Compliance
                      within the required time period (if applicable), or prize is returned as undeliverable,
                      potential winner forfeits prize. If the potential winner is at least 18 but still
                      considered a minor in his/her jurisdiction of residence, Sponsor reserves the right to
                      award the prize in the name of his/her parent or legal guardian, who will be required to
                      sign the Declaration of Compliance on the potential winners behalf and fulfill any other
                      requirements imposed on winner set forth herein. Potential winners must continue to
                      comply with all terms and conditions of these Official Rules, and winning is contingent
                      upon fulfilling all requirements. In the event that a potential winner is disqualified
                      for any reason, Sponsor will award the applicable prize to an alternate winner by random
                      drawing from among all remaining eligible entries. Only three (3) alternate drawings
                      will be held, after which the prize will remain un-awarded. Prizes will be fulfilled
                      approximately 8-10 weeks after the conclusion of the Contest.
                    </p>
                  </li>
                  <li>
                    <b>Prizes</b>
                    <p>
                      Weekly Prizes (5) will be announced on the following episodes of Super Happy Dragon
                      Lucky on YouTube:
                    </p>
                    <ol>
                      <li>Episode 0x2f - July 2 - 11am EDT</li>
                      <li>Episode 0x30 - July 9 - 11am EDT</li>
                      <li>Episode 0x31 - July 16 - 11am EDT</li>
                      <li>Episode 0x32 - July 23 - 11am EDT</li>
                      <li>Episode 0x33 - July 30 - 11am EDT</li>
                    </ol>
                    Grand Prize: 500,000 Dragon tokens
                  </li>
                  <li>
                    <b>General Conditions</b>
                    <p>
                      In the event that the operation, security, or administration of the Contest is impaired
                      in any way for any reason, including, but not limited to fraud, virus, bug, worm,
                      unauthorized human intervention or other technical problem, or in the event the Contest
                      is unable to run as planned for any other reason, as determined by Sponsor in its sole
                      discretion, the Sponsor may, in its sole discretion, either (a) suspend the Contest to
                      address the impairment and then resume the Contest in a manner that best conforms to the
                      spirit of these Official Rules or (b) terminate the Contest and, in the event of
                      termination, award the prize at random from among the eligible, non-suspect entries
                      received up to the time of the impairment. The Sponsor reserves the right in its sole
                      discretion to disqualify any individual it finds to be tampering with the entry process
                      or the operation of the Contest or to be acting in violation of these Official Rules or
                      in an unsportsmanlike or disruptive manner. Any attempt by any person to damage the
                      website or undermine the legitimate operation of the Contest may be a violation of
                      criminal and civil law, and, should such an attempt be made, the Sponsor reserves the
                      right to seek damages (including attorney&apos;s fees) and any other remedies from any
                      such person to the fullest extent permitted by law. Failure by the Sponsor to enforce
                      any provision of these Official Rules shall not constitute a waiver of that provision.
                    </p>
                  </li>
                  <li>
                    <b>Release and Limitations of Liability</b>
                    <p>
                      By participating in the Contest, entrants agree to release and hold harmless the
                      Sponsor, and each of their respective parents, subsidiaries, affiliates, advertising and
                      promotion agencies, other companies associated with the Contest, and each of their
                      respective officers, directors, employees, shareholders, representatives, and agents
                      (the Released Parties) from and against any claim or cause of action arising out of
                      participation in the Contest or receipt or use of the prize (including any travel or
                      activity related thereto), including, but not limited to: (a) any technical errors
                      associated with the Contest, including lost, interrupted or unavailable Internet Service
                      Provider (ISP), network, server, wireless service provider, or other connections,
                      availability or accessibility or miscommunications or failed computer, satellite,
                      telephone, cellular tower or cable transmissions, lines, or technical failure or
                      jumbled, scrambled, delayed, or misdirected transmissions or computer hardware or
                      software malfunctions, failures or difficulties; (b) unauthorized human intervention in
                      the Contest; (c) mechanical, network, electronic, computer, human, printing or
                      typographical errors; (d) application downloads, (e) any other errors or problems in
                      connection with the Contest, including, without limitation, errors that may occur in the
                      administration of the Contest, the announcement of the winner, the cancellation or
                      postponement of the event and/or the flyover, if applicable, the incorrect downloading
                      of the application, the processing of entries application downloads or in any
                      Contest-related materials; or (f) injury, death, losses or damages of any kind, to
                      persons or property which may be caused, directly or indirectly, in whole or in part,
                      from entrants participation in the Contest or acceptance, receipt or misuse of the prize
                      (including any travel or activity related thereto). Entrant further agrees that in any
                      cause of action, the Released Parties liability will be limited to the cost of entering
                      and participating in the Contest, and in no event shall the entrant be entitled to
                      receive attorney&apos;s fees. Released Parties are also not responsible for any
                      incorrect or inaccurate information, whether caused by site users, tampering, hacking,
                      or by any equipment or programming associated with or utilized in the Contest. Entrant
                      waives the right to claim any damages whatsoever, including, but not limited to,
                      punitive, consequential, direct, or indirect damages.
                    </p>
                  </li>
                  <li>
                    <b>Disputes</b>
                    <p>
                      Except where prohibited, each entrant agrees that any and all disputes, claims and
                      causes of action arising out of, or connected with, the Contest or any prize awarded
                      shall be resolved individually, without resort to any form of class action, and
                      exclusively by the appropriate court located in Washington. All issues and questions
                      concerning the construction, validity, interpretation and enforceability of these
                      Official Rules, entrants rights and obligations, or the rights and obligations of the
                      Sponsor in connection with the Contest, shall be governed by, and construed in
                      accordance with, the laws of Washington, without giving effect to any choice of law or
                      conflict of law rules, which would cause the application of the laws of any jurisdiction
                      other than Washington.
                    </p>
                  </li>
                  <li>
                    <b>Privacy</b>
                    <p>Information collected from entrants is subject to Sponsor&apos;s privacy policy.</p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Block>
      </Layout>
    </PageLayout>
  );
};

export default ContestRules;
